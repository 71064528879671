/* footer {
  padding: 2rem;
} */

.footer-image {
  width: 100%;
  height: auto;
}

.hidden-text {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
