.header {
  display: flex;
  align-items: center;
  min-height: 90px;
  position: relative;
  /* min-height line-height 높이 같이 변경 하기 */
}

.header-flex {
  display: flex;
  gap: 30px;
  margin-left: 5%;
}

.header-flex a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 90px;
  /* min-height line-height 높이 같이 변경 하기 */
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);  */
}

.header-dark {
  background-color: #373737;
}

/* 드롭다운 메뉴 스타일 */
.dropdown-menu {
  position: absolute;
  background-color: #373737;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: block;
  cursor: pointer;
}

.dropdown-menu a {
  color: rgb(255, 255, 255);
  padding: 12px 16px;
  line-height: 20px;
  display: block;
  text-align: left;
  text-decoration: none;
  font-weight: normal;
}

.dropdown-transparent {
  background-color: transparent;
  box-shadow: none;
}

/* 드롭다운 메뉴 스타일 끝 */

/* 언어 드롭다운 시작 */
.language-flex {
  display: flex;
  align-items: center; 
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 90px;
}

.language-dropdown img {
  width: 25px;
  height: 20px;
  margin-right: 10px;
}

.language-option {
  display: flex;
  align-items: center; 
  text-decoration: none;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  line-height: 50px;
}
/* 언어 드롭다운 끝 */


/* 모바일 뷰 설정 */

@media (max-width: 768px) {
  .urbanmix-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .header-flex {
    display: none;
  }

  .mobile-menu {
    position: absolute;
    top: 0;
    left: -300px;    /* 메뉴의 폭에 따라 조절 */
    width: 300px;    /* 원하는 폭으로 설정 */
    height: 100vh;    /* 뷰포트 높이 */
    background-color: #373737;
    transition: left 0.3s;    /* 슬라이드 효과 지속 시간 */
    overflow-y: auto;    /* 메뉴 내용이 많을 경우 스크롤 */
    color: white;
    margin-top: 90px;
    z-index: 1000; 
  }

  .mobile-menu a {
    display: block;
    color: white;    /* 글자 색상을 흰색으로 설정 */
    text-decoration: none;    /* 밑줄 제거 */
    margin-top: 20px;
    margin-left: 20px;
  }

  .sub-menu {
    padding-left: 20px;
  }

  .hamburger {
    font-size: 25px;
    color: white;
    display: block;
    cursor: pointer;
    margin-left: 5%;
  }

  .menu-open {
    left: 0;
  }
}

@media (min-width: 769px) {
  .urbanmix-img {
    margin-left: 40px;
  }

  .mobile-menu {
    display: none;
  }

  .hamburger {
    display: none;
  }
}