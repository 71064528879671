.gellery {
  display: flex;
  margin-top: 60px;
  min-height: 1080px;
}

.gellery-tab {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
}

@media (max-width: 1600px) {

  /* 해상도가 1600px 이하일 때의 스타일 변경 */
  .gellery-tab {
    margin-left: 2%;
    margin-right: 2%;
  }
}

/* link = a 밑줄 제거 */
.gellery-tab a {
  text-decoration: none;
  color: inherit;
  margin-top: 10px;
}

.gellery-tab strong {
  margin-top: 10px;
}

.gellery-main {
  margin-top: 30%;
  margin-bottom: 30%;
  width: 120px;
  height: 52px;
}

.profile-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
}

.profile-container a {
  margin: 0 1px;
}

/* 모바일 스타일 */
@media (max-width: 768px) {
  .gellery {
    display: flex;
    flex-direction: column;
    margin: 0px;
  }

  .gellery-tab {
    display: none;
  }

  .profile-container {
    margin-top: 10px;
    grid-template-columns: 1fr 1fr;
    /* 3개에서 2개로 변경 */
  }

  .profile-container a img {
    width: 100%;
    height: auto;
  }

  .profile-ex a img {
    width: 100%;
    height: auto;
  }
}