.detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1080px;
  margin-top: 60px;
}

@media (max-width: 768px) {
  .detail img {
    width: 100%;
    height: auto;
  }
}