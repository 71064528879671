.intro {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  min-height: 1080px;
}

.intro-map {
  width: 35%;
  height: 400px;
  margin-right: 30px;
}

@media (max-width: 768px) {
  .intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* 가로축에서 시작점에 정렬 */
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .intro-map {
    width: 100%;
    height: 400px;
  }
}