.App {
  position: relative;
}

.bg-image {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
