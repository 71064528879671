.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 가로 3줄 그리드 */
  gap: 20px; /* 각 그리드 아이템 사이의 간격 설정 */
  margin-top: 20px; /* 그리드와 상단 컨테이너 사이 간격 설정 */
}

.grid-item {
  width: 100%; /* 그리드 아이템의 너비를 100%로 설정하여 가로 폭에 맞게 배치 */
  max-width: 300px; /* 그리드 아이템의 최대 너비를 설정 (선택사항) */
}
