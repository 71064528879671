.reserve {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1080px;
}

.reserve button {
  background-color: #303030;
  color: white;
  border: none;
  padding: 12px 25px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .reserve img {
    width: 100%;
    height: auto;
  }
}